.logo-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #e1e1e1;
  padding: 24px;
  border-radius: 2px;
}

.logo {
  width: 50%;
}

.logo-text {
  text-align: center;
  width: 80%;
  padding-bottom: 24px;
}

.container {
  padding: 64px 24px 24px;
}

.login-container-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authentication-title {
  padding-bottom: 32px;
}

.optional-button {
  cursor: pointer;
}

.otp-label {
  padding: 16px 0px 32px;
  flex-wrap: wrap;
  width: 85%;
}

.resend-label {
  cursor: pointer;
}

.error-message {
  color: #ff4d4f;
  font-size: 14px;
}

.timer-content {
  padding: 32px 0px;
}

.timer-message {
  overflow-wrap: break-word;
  width: 100%;
}

.timer-container {
  display: flex;
  justify-content: end;
  flex-direction: column;
}
