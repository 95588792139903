.purchase-form-container {
    margin: 21px;
    flex: 1;
    input, .ant-picker, .ant-input-number {
        width: 100%;
    }

    .form-summary-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;

        .amount-container {
            display: flex;

            &.total-amount {
                border-top: 1px solid #ccc;
                margin-top: 7px;
                padding-top: 5px;
            }

            &.pending {
                > div {
                    color: red;
                    font-weight: 500;
                }
            }
            &.paid {
                > div {
                    color: green;
                    font-weight: 500;
                }
            }
            > div:first-child {
                font-weight: 500;
            }
            > div:last-child {
                margin-left: 14px;
                width: 100px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}