button.ant-btn.ant-btn-primary.menu-button {
    padding: unset !important;
    margin: unset !important;
    border: unset !important;
    background: #2f4f4f  !important;
    width: max-content !important;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
    width: 100%;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block !important;
    margin-right: 4px !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    font-family: SimSun, sans-serif;
    line-height: 1 !important;
    content: '*' !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
    content: '' !important;
}

.ant-form-item-label > label::after {
    display: none !important;
    content: '' !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #2d6282 !important;
}

th.ant-table-cell {
    font-size: 11px;
    font-weight: 600 !important;
}
td.ant-table-cell {
    font-size: 11px;
    font-weight: 500 !important;
}

span.ant-input-group-addon {
    background: #ffd666;
    color: #000;
    padding: 0px 10px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #ffd666 !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(255, 214, 102, 0.7) !important;
    border-color: #ffd666 !important;
}

.ant-input-group-wrapper {
    margin: 15px 0px;
}

.ant-input-lg {
    font-size: 12px !important;
}

.ant-table-body {
    overflow-y: hidden;
}

.ant-table-body::-webkit-scrollbar {
    height: 8px;
  }

  .ant-table-body::-webkit-scrollbar:vertical {
    display: none !important;
  }
   
.ant-table-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
   
.ant-table-body::-webkit-scrollbar-thumb {
    background-color: #ff9c07;
    outline: 1px solid #ff9c07;
}

th.ant-table-cell {
    background: #ff9c07 !important;
    color: #000 !important;
}

.ant-input-affix-wrapper-lg {
    padding: 0.5px 11px !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    border-bottom: 1px solid #ff9c07;
}
.ant-table-tbody > tr.ant-table-row > td {
    border-bottom: 1px solid #ff9c07;
}

.ant-input[disabled], .ant-input-prefix {
    color: rgb(4 4 4 / 55%) !important;
}

.ant-input-affix-wrapper-disabled {
    background-color: #fafafa !important;
}

.ant-checkbox-group {
    display: flex !important;
    justify-content: space-around !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0px 45px !important;
}

.ant-menu-horizontal {
    display: flex !important;
    justify-content: center !important;
    border: unset !important;
}

  p.form-title {
    margin-bottom: 30px;
  }
   p.form-title {
    color: #333333;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
  }

  .ant-drawer-close {
      padding: 5px !important;
      top: 10px !important;
      right: 10px !important;
  }

  .ant-card-extra {
    padding: 5px 0px !important;
    font-size: 24px !important;
}

.ant-card-head-title {
    padding: 5px 0px !important;
}

.ant-modal {
    width: 100% !important;
}

html {
    scroll-behavior: smooth;
}

.ant-affix {
    background-color: #fff;
    width: 100%;
}

.pdfViewer .page {
    border: unset !important;
}

.pdf-container > div {
    overflow: auto;
}

.toolbarButton.pageUp, .toolbarButton.pageDown, .toolbarField.pageNumber {
    display: none !important;
}

.mobile__pdf__container footer {
    background-image: unset !important;
    box-shadow: unset !important;
    height: 40px !important;
    position: absolute !important;
    bottom: 70px !important;
}

.mobile__pdf__container .toolbarButton.zoomOut { 
    background-image: url("../images/minus.png") !important;
    background-size: 25px !important;
    width: 35px !important;
    height: 35px !important;
    left: 82% !important;
}
.mobile__pdf__container .toolbarButton.zoomIn {
    background-image: url("../images/plus.png") !important;
    background-size: 25px !important;
    width: 35px !important;
    height: 35px !important;
    top: -40px !important;
}

.mobile__pdf__container #viewerContainer {
    position: relative !important;
    width: 100%;
    top: 0px !important;
    bottom: 0px !important;
}

.ant-modal-body {
    padding: 5px !important;
}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn:visited {
    border: unset !important;
    border-color: transparent !important;
}

.ant-btn[type="button"]:hover {
    background-color: #ff9c07 !important;
    color: #000 !important;
}

.ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover {
    background-color: #ff9c07 !important;
    border: unset !important;
    border-color: transparent !important;
    color: #000 !important;
}

.ant-pagination-item-active {
    background-color: #ffd666 !important;
    border: unset !important;
    border-color: transparent !important;
    color: #000 !important;
}

.ant-pagination-item-active a, .ant-pagination-item a:hover, .ant-pagination-item a:active, .ant-pagination-item a:focus, .ant-pagination-item a:visited {
    color: #000 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffd666 !important;
    color: #000 !important;
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover, .ant-menu-item a:hover {
    color: #000 !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #ff9c07 !important;
}

.ant-menu-item:focus-visible, .ant-menu-submenu-title:focus-visible {
    box-shadow: unset  !important;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
    background-color: rgba(255, 214, 102, 0.7) !important;
}

.ant-switch-checked {
    background-color: #ff9c07 !important;
}

.ant-switch-checked:focus, .ant-switch-checked:active, .ant-switch-checked:visited {
    box-shadow: 0 0 0 2px rgb(255, 214, 102, 0.7) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important;
    font-weight: 500;
}

.ant-tabs-ink-bar {
    background-color: #ff9c07 !important;
}

.ant-table-column-sorter-up, .ant-table-column-sorter-down {
    color: #000 !important;
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: #fff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid #ff9c07 !important;
}

.invoice-component-container .ant-row.ant-form-item.preview-btn .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
    margin-right: 24px;
    margin-bottom: 24px;
}

.header-container {
    box-shadow: 0px 7px 5px 0px rgb(199 195 195 / 75%);
    -webkit-box-shadow: 0px 7px 5px 0px rgb(199 195 195 / 75%);
    -moz-box-shadow: 0px 7px 5px 0px rgba(199,195,195,0.75);
    padding: 0px 12px;
}

.footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e5e5e5;
    width: 100%;
    margin-top: 15px;
}
