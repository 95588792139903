.list-container {
    .list-card {
        background: #e5e5e5;
        margin: 10px 0px;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 5px 10px;

        &.pending-container {
            border-color: #f4683d;
        }

        .list-card-row {
            display: flex;
            justify-content: space-between;
            color: #ac6c0d;

            &.amount-paid {
                .list-card-column {
                    color: green;
                    font-weight: 500;
                }
            }
            &.pending-amount {
                .list-card-column {
                    color: red;
                    font-weight: 500;
                }
            }

            .list-card-column {
                font-size: 12px;

                &.customer-name, &.purchase-date {
                    font-size: 10px;
                    font-weight: 800;
                    color: #9c9c9ccc;
                    line-height: 10px;
                }

                &.product-name, &.total {
                   margin-top: 7px;
                    font-size: 15px;
                    font-weight: 700;
                    color: #000000;
                }
            }

            &.delete {
                justify-content: flex-end;
                align-items: center;
                .list-card-column.delete {
                    color: #d84316;
                    cursor: pointer;
                    font-weight: 500;
                    margin-top: 5px;
                }
                .list-card-column.update {
                    margin-top: 5px;
                    cursor: pointer;
                    margin-right: 10px;
                }
            }
        }
    }

    .list-pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 12px;
        font-weight: 700;

        .pagination-arrow {
            margin: 0px 10px;
        }
    }
}