.purchase-details-container {
    flex: 2;
    padding: 15px;

    .filter-container {
        display: flex;
        justify-content: flex-end;

        .ant-picker {
            margin: 0px 10px;
            width: 150px;
        }

        .ant-select {
            width: 200px;
        }
    }
    .overall-summary {
        
        h3 {
            margin-top: 15px;
        }
        .overall-summary-item-list {
            justify-content: space-between;

            &.amount-paid-pending {
                margin: 14px 0px;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
            }

            > .amount-paid-container {
                color: green;
            }

            > .amount-pending-container {
                color: red;
            }
    
            > div {
                justify-content: space-between;
                > span:last-child {
                    font-weight: 700;
                }
            }
        }
    }
    
}